.wrapper::after {
  position: fixed;
  z-index: -2;
  width: 100%;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  background-color: white;
}

.wrapper .circular-background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: '';
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.wrapper .circular-background::before {
  background: linear-gradient(
    0deg,
    #00c6c9 -32.82%,
    #3aedf0 50.76%,
    rgba(255, 255, 255, 0) 109.79%
  );
  border-radius: 50%;
  filter: blur(300px);
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: '';
  bottom: 0;
  top: 0;
  transform: translateY(87%);
  background-color: transparent;
  filter: blur(300px);
}

input {
  width: 100%;
}

.survey-description {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #a1b4c4;
  margin-bottom: 51px;
}

/* 
  ajustando o container global para a nova estrutura
*/
.newWrapper {
  padding: 0 20px;
  max-width: 1280px;
  margin: 0 auto;
}

.newWrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.newWrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.newWrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #61008aa8;
}

/* Handle on hover */
.newWrapper::-webkit-scrollbar-thumb:hover {
  background: #61008a;
}

.newWrapper::-webkit-scrollbar-corner {
  background: transparent;
}

@media only screen and (min-width: 640px) {
  .newWrapper {
    padding: 44px 20px;
  }
}

.newContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 46px;

  padding: 0;
  min-height: 100vw;
  height: 100%;
}

.newContent .newToggleThemeSwitch {
  display: initial;
  position: fixed;
  right: 58px;
  bottom: 66px;
}

@media only screen and (min-width: 640px) {
  .newContent .newToggleThemeSwitch {
    display: initial;
  }

  .newContentInitialSurvey .newToggleThemeSwitch {
    display: initial;
    position: fixed;
    right: 58px;
    bottom: 66px;
  }
}

@media only screen and (min-width: 640px) {
  .newContent {
    min-height: 100%;
    gap: 114px;
    justify-content: flex-start;
  }

  .newContent .newHeader {
    margin: initial;
  }
}

.newContentFinishSurvey {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 59px;

  padding: 0;
  min-height: 100vw;
  height: 100%;
}

.newContentFinishSurvey .logo {
  display: initial;
}

.newContentFinishSurvey .newProgressBar {
  display: none;
}

.newContent .newHeader {
  padding: 0;
}

/* 
  quando da inicio a uma pesquisa
*/

@media only screen and (min-width: 640px) {
  .newContentInitialSurvey {
    min-height: 100%;
  }
}

.newContentInitialSurvey .newHeader {
  padding: 0;
}

/* 
  componente de progressBar
*/
/* .newHeader .newProgressBar {
  position: initial;
}

.newHeader .newProgressBar .newProgressBarContent {
  display: flex;
  flex-direction: column;
  position: relative;

  height: 100%;
  padding: 0 0 14px 0;
  margin: 0;
  border-bottom: 1px solid #c0e6e7;
}

.newHeader .newProgressBar .newProgressBarContent .newIcon {
  display: none;
}

@media only screen and (min-width: 640px) {
  .newHeader .newProgressBar .newProgressBarContent {
    padding: 11px 25px 31px 20px;
    max-width: 360px;
    width: 100%;
  }

  .newHeader .newProgressBar .newProgressBarContent .newIcon {
    position: absolute;
    right: 23.84px;
    bottom: 26.84px;

    display: initial;
    width: 38.16px;
    height: 38.16px;
  }
}

.dark .newHeader .newProgressBar .newProgressBarContent {
  border-bottom: 1px solid #57496f;
  background-color: transparent;
}

@media only screen and (min-width: 640px) {
  .dark .newHeader .newProgressBar .newProgressBarContent {
    background-color: #36004c;
    border: none;
  }
}

.newHeader .newProgressBar .newProgressBarContent h1 {
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (min-width: 640px) {
  .newHeader .newProgressBar .newProgressBarContent h1 {
    margin: 0 0 19px 0;
  }
}

.newHeader .newProgressBar .newProgressBarContent .rc-slider {
  padding: 0;
  height: 4.84px;
}

.newHeader .newProgressBar .newProgressBarContent .rc-slider .rc-slider-rail {
  height: 4.84px;
}

.newHeader .newProgressBar .newProgressBarContent .rc-slider-track {
  height: 4.84px !important;
}

@media only screen and (min-width: 640px) {
  .newHeader .newProgressBar .newProgressBarContent .rc-slider {
    height: 12.4px;
  }

  .newHeader .newProgressBar .newProgressBarContent .rc-slider .rc-slider-rail,
  .newHeader .newProgressBar .newProgressBarContent .rc-slider-track {
    height: 12.4px !important;
  }
} */

/* 
  area da pergunta
*/

.newSurveyWrapper .newSurveyContent {
}
/* @media only screen and (min-width: 640px) {
  .newSurveyWrapper .newSurveyContent {
    overflow-y: initial;
    max-height: initial;
  }
} */

.newSurveyWrapper .newSurveyContent .newContainerGradeUnica,
.newSurveyWrapper .newSurveyContent .newContainerGradeMultiplier {
  justify-content: flex-start;
}

/* 
  mensagem de erro
*/
.newSurveyWrapper .newSurveyContent .newMessageError {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #eb5757;
  margin-bottom: 11px;
}

/*
  estilizando o novo posicionamento dos botões 
  de avançar e voltar da aplicação 
*/
.newSurveyWrapper .newSurveyButtonsContainers {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 9px;
  flex-wrap: nowrap;

  margin: 0;
  padding: 14px 10px;
}

.newSurveyWrapper .newSurveyButtonsContainers .line {
  display: block;
  min-height: 1px;
  width: 100%;
  background-color: #02bfc2;
}

@media only screen and (min-width: 640px) {
  .newSurveyWrapper .newSurveyButtonsContainers .line {
    display: none;
  }
}

.dark .newSurveyWrapper .newSurveyButtonsContainers .line {
  background-color: #57496f;
}

.newSurveyButtonsContainers .newSurveyButtons {
  display: flex;
  justify-content: center;
}

.newSurveyButtonsContainers .newSurveyInfoButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
}

@media only screen and (min-width: 640px) {
  .newSurveyButtonsContainers .newSurveyInfoButtons {
    display: none;
  }
}

.newSurveyButtonsContainers .newSurveyInfoButtons div {
  display: flex;
  gap: 4px;
  color: #61008a;
}

.dark .newSurveyButtonsContainers .newSurveyInfoButtons div {
  color: #fff;
}

.newSurveyButtonsContainers .newSurveyInfoButtons div span {
  font-weight: 700;
}

/* component de instabilidade */
.newComponentInstabilidade {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 
customizando tags de erro
*/
.newCustomTagErrorWrapper {
  border: solid 1px #eb5757;
}

.newCustomTagErrorWrapper p {
  color: #eb5757 !important;
}

/* 
  página de quiz expirado
*/
.newCustomPageExpiredQuiz {
  height: 100%;
  overflow: hidden;
}

.newCustomPageExpiredQuiz .newExpiredQuizContent {
  max-width: 996px;
  width: 100%;
  position: relative;
  padding: 0;
}

.newCustomPageExpiredQuiz .newExpiredQuizContent .newExpiredQuizImage {
  margin: -95px 20px -12px 0;
}

.newCustomPageExpiredQuiz .newExpiredQuizContent .newExpiredQuizText {
  margin-top: 60px;
}

.newCustomPageExpiredQuiz .newButtonViewTensai {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  margin-top: 61px;
  background-color: #6a1995;
  width: 323px;
  height: 43px;
  margin: 61px auto 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

/* page loading */
.contentLoadingPage {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
}
