@tailwind base;
@tailwint components;
@tailwind utilities;

/*Question/index.tsx classes*/

* {
  transition: background-color 0.15s, color 0.15s;
}
/*
  Tendo em vista a quantidade de vezes em que essa classe se repetia em vários pontos de código de modo idêntico
  para facilitar a manutenabilidade e para reduzir a duplicidade de código, foi criada esta classe usando a diretiva @apply
  do tailwindcss para que no lugar das regras tailwind fossem colocadas de maneira verbosa, fosse substituída pelos títulos das 
  classes em que foi aplicada esta diretiva.
*/

/* Removido a border devido a refatoração de pergunta aberta */
.pergunta-aberta {
  @apply flex items-center font-roboto  pb-3;
}

.question-label-text {
  @apply text-16p sm:text-statement font-normal font-roboto text-darkPurple dark:text-white;
}

.regra-wrapper {
  @apply mb-10 mr-2 border border-darkPurple dark:border-fontTensai rounded w-fit-content;
}

.obrigatoriaTagBorder {
  @apply border-obrigatoriaTag;
}

.regra-text {
  @apply sm:text-sm text-xs mx-2 my-1 text-darkPurple dark:text-fontTensai;
}

.obrigatoriaTagText {
  @apply text-obrigatoriaTag;
}

.erro,
.erro p {
  @apply border-contrasteTensai text-contrasteTensai;
}

.button-box {
  @apply mt-16 md:mt-0 text-center flex justify-center gap-1 flex-wrap;
  margin-bottom: 30px;
}

.pergunta-wrapper {
  @apply justify-center mt-2 pt-5 pb-7 px-2 border-solid rounded sm:mb-0 mb-20 mx-auto max-w-7xl;
}

.erro-border {
  @apply border-2 border-alert;
}

.mensagem-erro {
  @apply sm:text-sm text-alert text-xs;
}

/*Question/PerguntaHorario/index.tsx*/

@layer components {
  .time-wrapper {
    @apply flex flex-col static xl:relative;
  }
  .react-timekeeper-wrapper {
    @apply fixed flex flex-col box-border shadow-md w-full h-full md:h-96 md:w-80 left-0 top-0 md:left-2/4 md:top-2/4 md:transform md:-translate-x-2/4 md:-translate-y-2/4;
  }

  .react-timekeeper {
    @apply w-full h-full;
  }

  .react-timekeeper__top-bar {
    @apply flex justify-center items-end;
  }

  .react-timekeeper__clock-wrapper {
    @apply h-full flex flex-col justify-center items-center gap-y-2;
  }

  .css-1r6tdpo-Meridiems {
    @apply flex justify-between w-72;
  }

  .clock-input-buttons {
    @apply flex items-center justify-center gap-x-8 box-border border-0 bg-detalheTensai pb-3.5 pt-6;
  }
  .clock-icon {
    @apply w-5 h-5;
  }
  .time-input-wrapper {
    @apply flex items-center pb-1 gap-x-2 cursor-pointer font-roboto border-b border-contrasteTensai;
  }

  .form-input {
    @apply bg-transparent border-none outline-none text-darkPurple dark:text-contrasteTensai cursor-pointer;
  }
}

/*Section/IniciarPesquisa/index.tsx*/

.descricao-pesquisa {
  @apply text-darkPurple dark:text-fontTensai max-w-lg text-center mx-10;
}

.botao-posicao {
  @apply justify-center flex mt-10;
}

/*pages/PaginaPrincipalPesquisa/index.tsx*/

.barra-progresso {
  @apply box-border flex justify-end w-full sm:relative fixed bottom-0 left-0;
}

.logo {
  @apply my-auto;
}

/*pages/Main/index.tsx*/

@layer components {
  .wrapper {
    @apply h-screen w-auto relative sm:pb-0 sm:overflow-y-auto;
  }
}

/*components/title/index.tsx*/

.texto-label {
  @apply font-roboto text-contrasteTensai text-base;
}

.texto-titulo {
  @apply font-roboto text-center md:text-title text-darkPurple dark:text-white text-2xl md:pt-5 pt-1 md:pb-9 pb-5 font-bold;
}

.texto-titulo {
  line-height: 25px;
}

@media only screen and (min-width: 640px) {
  .texto-titulo {
    line-height: 50px;
  }
}
